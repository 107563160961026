<template>
  <div id="gifBox">
    <div>
      <p @click="btnClikc(3)">
        <span>立即交易</span>
        <!-- <span>10000模拟金</span> -->
      </p>
      <img @click="btnClikc(1)" src="../../../static/index/gif.gif" alt="" style="width:160px">
      <p @click="btnClikc(2)">
        <span>开立账户</span>
        <span>领$588+$20000+￥1588</span>
      </p>
    </div>
  </div>
</template>

<script>
import { handleMT5Trade } from '../../../utils/tools'
export default {
  name:'gif',
  methods:{
    btnClikc(attr){
      let domin = document.domain;
      if(attr == 0){
        window.open('https://' + domin + '/uc/signUp/trial');
      }else if(attr == 1){
        window.open('https://' + domin + '/uc/assets/deposit');
      }else if(attr == 3){
        handleMT5Trade()
      }else{
        window.open('https://' + domin + '/uc/signUp/real');
      }
    }
  }
}
</script>

<style lang="less" scoped>
  #gifBox{
    width: 100%;
    margin-top: 15px;
    div{
      width: 65%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 340px;
        height: 86px;
        background-color: #1f67df;
        border-radius: 15px;
        cursor: pointer;
        span{
          color: #ffffff;
          font-size: 30px;
        }
        span:nth-child(2){
          font-size: 16px;
          margin-top: 5px;
          text-align: center;
        }
      }
      p:nth-child(3){
        background-color: #d52226;
      }
    }
  }
  @media screen and (max-width: 500px) {
    #gifBox{
      div{
        width: 100%;
        box-sizing: border-box;
        padding: 0 6px;
        p{
          width: 32%;
          height: auto;
          border-radius: 5px;
          height: 80px;
          span:nth-child(1){
            font-size: 14px;
          }
          span:nth-child(2){
            font-size: 10px;
          }
        }
        img{
          width: 28%;
          height: auto;
        }
      }
    }
  }
</style>
